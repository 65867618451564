import { render, staticRenderFns } from "./new-briefing.vue?vue&type=template&id=575b6d5a&"
import script from "./new-briefing.js?vue&type=script&lang=js&"
export * from "./new-briefing.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports