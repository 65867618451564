import Axios from 'axios';
import {mask} from 'vue-the-mask';
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize';

Vue.use(TextareaAutosize);

export default {

  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      mainspeciality: '',
      mainSpecialityInput: '',
      specialitiesInput: '',
      errors: {
        mainspeciality: [],
        specialities: [],
        company_email: [],
      },
      form: {
        title: "",
        address: 0,
        specialities: [],
        worktype: "remote",
        company_name: "",
        company_email: "",
      },
      date_start: null,
      date_end: null,
      specialities: [],
      collapse: {
        one: true,
        two: false,
        three: false,
      },
      attachments: [],
      professional: {
        legal: {},
        financial: {
          type: 'pj',
        },
      },
      banks: [],
      bankInput: '',
      selectedBank: '',
      syncing: false,
    }
  },

  mounted() {

    $('[data-toggle="tooltip"]').tooltip();

    jQuery(document).on("input", "#texto-projeto", function() {
      var limite = 800;
      var caracteresDigitados = jQuery(this).val().length;
      var caracteresRestantes = limite - caracteresDigitados;

      if (caracteresRestantes <= 0) {
        var comentario = jQuery("textarea[name=texto-projeto]").val();
        jQuery("textarea[name=texto-projeto]").val(comentario.substr(0, limite));
        jQuery(".caracteres").text("(0)");
      } else {
        jQuery(".caracteres").text("- (" + caracteresRestantes + ")");
      }
      if ( jQuery("textarea[name=texto-projeto]").val() == "") {
        jQuery(".caracteres").text("");
      }

    });
   //
   // jQuery('#texto-projeto').bind('copy paste', function (e) {
   //    e.preventDefault();
   // });

    // document.getElementById("texto-projeto").onkeypress = function (event) {
    //   if (event.keyCode == 13) {
    //     event.preventDefault();
    //   }
    // };

    feather.replace();
    console.log(this.collapse);

    if (this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    if (this.$store.state.role == 'client') _this.$router.push({ path: '/dashboard/client' });
    if (this.$store.state.role == '') this.$router.push({ path: '/' });

    axios
      .get('/api/v1/users/' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.professional = response.data.data;
          this.selectedBank = this.professional.financial.bank_id;
        }
      });

    axios
      .get('/api/v1/banks', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.banks = response.data.data;
          let index = this.banks.map(function (e) { return e.id; }).indexOf(this.professional.financial.bank_id);
          if (index > -1) this.bankInput = this.banks[index].name;
        }
      });

  },

  computed: {
  },

  watch: {

    // date_start: function () {
    //
    //   let start = this.date_start ? new Date(this.date_start) : Date.now();
    //   let end = this.date_end ? new Date(this.date_end) : Date.now();
    //   let difference = end.getTime() - start.getTime();
    //   this.days = difference / (1000 * 3600 * 24);
    //
    //   if (this.days + 1 > 0)
    //     this.form.value = this.money.prefix + ((this.days + 1) * 300) + ",00";
    // },

    // date_end: function () {
    //
    //   let start = this.date_start ? new Date(this.date_start) : Date.now();
    //   let end = this.date_end ? new Date(this.date_end) : Date.now();
    //   let difference = end.getTime() - start.getTime();
    //   this.days = difference / (1000 * 3600 * 24);
    //
    //   if (this.days + 1 > 0)
    //     this.form.value = this.money.prefix + ((this.days + 1) * 300) + ",00";
    // },

  },

  methods: {
    validateList: function() {
      //
    },

    validateMainSpeciality: function(e) {
      let text = this.mainSpecialityInput;
      let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        // não está ainda na lista selecionada de habilidades
        this.mainspeciality = text;
      }
      this.mainSpecialityInput = '';
    },

    validateListComplementares: function(e) {
      if (this.form.specialities.length < 3) {
        let text = this.specialitiesInput;
        let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
        let double = this.form.specialities.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.form.specialities.push(text);
        }
      }
      this.specialitiesInput = '';
    },

    removeSpeciality: function(s) {
      // remove habilidade do briefing
      let index = this.form.specialities.indexOf(s);
      if (index > -1) {
        this.form.specialities.splice(index, 1);
      }
    },

    handleAttachmentUpload(ev) {
      this.attachments.push(this.$refs.attachments.files[0]);
      ev.target.value = '';
    },

    removeAttachment: function(a) {
      let index = this.attachments.map((e) => {return e.name}).indexOf(a.name);
      if (index > -1) {
        this.attachments.splice(index, 1);
      }
    },

    submit: function(e) {
      // check if user has basic info
      let financial = this.professional.financial;
      if (this.me.name == '' ||
          financial.razao == '' ||
          !financial.razao ||
          financial.agency == '' ||
          !financial.agency ||
          financial.account == '' ||
          !financial.account
      ) {
        $('#financial-account-modal').modal('show');
        return;
      }
      let _this = this;
      let fail = false;
      _this.syncing = true;
      let form = _this.form;
      let formData = new FormData();

      if (form.company_email == '') {
        _this.syncing = false;
        _this.errors.company_email.push("Você precisa preencher o e-mail do seu cliente.");
        fail = true;
      }

      if (form.title == '') {
        _this.syncing = false;
        _this.errors.title.push("Você precisa preencher um título.");
        fail = true;
      }

      // after all validation, return if fail
      if (fail) return;

      formData.set("value", parseInt(form.value.replace(/\D/g,'')));
      formData.set("title", form.title);
      formData.set("tasks", form.tasks || " ");
      formData.set("date_start", _this.date_start);
      formData.set("date_end", _this.date_end);
      formData.set("company_computer", form.company_computer);
      formData.set("worktype", form.worktype);
      formData.set("hire_type", 'direct');
      formData.set("company_name", form.company_name);
      formData.set("company_email", form.company_email);

      if (_this.attachments.length) {
        for (let i = 0; i < _this.attachments.length; i++) {
          formData.append("attachments[]", _this.attachments[i]);
        }
      }

      // send data to api
      axios
      .post('/api/v1/hirings', formData, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        if (response.data.error) {
          _this.errors = response.data.error;
          if (_this.errors.specialities) {
            $('#h3-1').trigger('click');
          }
          else if (_this.errors.date_end || _this.errors.date_start || _this.errors.attachments || _this.errors.tasks || _this.errors.title) {
            $('#h3-2').trigger('click');
          }
          else if (_this.errors.value || _this.errors.company_computer || _this.errors.worktype) {
            $('#h3-3').trigger('click');
          }
          if (response.data.error.plan) $('#plan-exceeded-modal').modal('show');
          _this.syncing = false;
        } else {
          _this.$router.push({ path: '/dashboard/professional' })
        }
      });

    },

    toSettings() {
      $('#complete-info-modal').modal('hide');
      this.$router.push({path: '/dashboard/professional/settings/'});
    },

    toPlans() {
      $('#plan-exceeded-modal').modal('hide');
      this.$router.push({path: '/dashboard/client/mycommunity-notamember'});
    },

    handleCollapse(index) {
      switch (index) {
        case 1:
        this.collapse.one = !this.collapse.one;
        this.collapse.two = this.collapse.three = false;
        break;
        case 2:
        this.collapse.two = !this.collapse.two;
        this.collapse.one = this.collapse.three = false;
        break;
        case 3:
        this.collapse.three = !this.collapse.three;
        this.collapse.one = this.collapse.two = false;
        break;
        default:
        this.collapse.one = this.collapse.two = this.collapse.three = false;
        break;
      }
    },

    setBankId(e) {
      let index = this.banks.map(function (e) { return e.name; }).indexOf(this.bankInput);
      if (index > -1) this.selectedBank = this.banks[index].id;
      // console.log(this.selectedBank);
    },

    submitFinancial() {
      let _this = this;
      let data = {};
      let professional = _this.professional;
      // Dados bancários
      data.cnpj = professional.legal.cnpj;
      data.bank = _this.selectedBank;
      data.type = professional.financial.type;
      data.razao = professional.financial.razao;
      data.agency = professional.financial.agency;
      data.account = professional.financial.account;
      axios
        .put('/api/v1/users/' + _this.me.id, data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            _this.financial.errors = response.data.error;
          } else {
            $('#financial-account-modal').modal('hide');
          }
        });
    },

  }

}
